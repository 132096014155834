@font-face {
  font-family: "Cairo";
  src: local("Cairo"), url(../fonts/Cairo-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Cairo-bold";
  src: local("Cairo"), url(../fonts/Cairo-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Cairo-semi";
  src: local("Cairo"), url(../fonts/Cairo-SemiBold.ttf) format("truetype");
}

:root {
  --primary-color: #03a247;
  --secondary-color: #f4a52e;
  --third-color: #c2ffda;
  --second-primary-color: #045d7a;
  --second-secondary-color: #ff8552;
  --second-third-color: #39393a;
  --third-primary-color: #412234;
  --third-secondary-color: #996888;
  --third-third-color: #e26d5a;
  --dark-color: #8a8a8a;
  --darker-color: #3e3e3e;
  --light-color: #f4f4f4;
  --error-color: #931621;
  --warning-color: #ecd444;
  --success-color: #28a745;
}
html {
  scroll-padding-top: 55px;
}
body {
  font-family: "Cairo" !important;
  margin: 0 !important;
}
main {
  /* background-color: #fafafa; */
  margin-bottom: 100px;
}
/* li {
  display: block;
} */
/*GLOBALS*/
a {
  text-decoration: none;
  color: inherit;
}
.rtl {
  direction: rtl;
}
.ltr {
  direction: ltr;
}
/*texts*/
.cairo {
  font-family: "Cairo" !important;
}
text {
  font-family: "Cairo";
}
.center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
  text-align-last: end;
  -moz-text-align-last: end;
  -ms-text-align-last: end;
}
.text-start {
  text-align-last: start;
  -moz-text-align-last: start;
  -ms-text-align-last: start;
}
.text-primary {
  color: var(--primary-color);
}
.text-light {
  color: var(--light-color);
}
.text-error {
  color: var(--error-color);
}
.text-secondary {
  color: var(--secondary-color);
}
.text-success {
  color: var(--success-color);
}
.text-shadow-grey {
  text-shadow: 0 0 2px var(--dark-color);
}
.text-shadow-light {
  text-shadow: 0px 0px 2px var(--light-color);
}
.text-normal {
  font-size: medium;
}
.text-small {
  font-size: small;
}
.text-smaller {
  font-size: smaller;
}
.text-larger {
  font-size: larger;
}
.text-xlarge {
  font-size: x-large;
}
.text-xxlarge {
  font-size: xx-large;
}

.bold {
  /* font-weight: 600 !important; */
  font-family: "Cairo-semi" !important;
}
.bolder {
  /* font-weight: 600 !important; */
  font-family: "Cairo-bold" !important;
}
.underlined {
  text-decoration: underline;
  text-underline-position: under;
}
/* Padding */
.xp {
  padding: 0.2rem;
}
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-15 {
  padding: 1.5rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-15 {
  padding: 1.5rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 0 3rem;
}
.px {
  padding: 0 0.5rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}
.pt {
  padding-top: 0.5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pb {
  padding-bottom: 0.5rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.clearp {
  padding: 0;
}
/* Margin */
.xm {
  margin: 0.1rem;
}
.m {
  margin: 0.5rem;
}

.m-1 {
  margin: 1rem;
}

.m-2 {
  margin: 2rem;
}

.m-3 {
  margin: 3rem;
}
.myx {
  margin: 0.2rem 0;
}
.my {
  margin: 0.5rem 0;
}

.my-1 {
  margin: 1rem 0;
}

.my-2 {
  margin: 2rem 0;
}

.my-3 {
  margin: 3rem 0;
}

.mx {
  margin: 0 0.5rem;
}
.mx-1 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.mt {
  margin-top: 0.5rem;
}
.mt-1 {
  margin-top: 1rem;
}
.margin-auto {
  margin: auto;
}
.clearm {
  margin: 0 !important;
}
.clearp {
  padding: 0 !important;
}
.fullheight {
  height: 100%;
}
.fullheightVH {
  height: 100vh;
}
.fullheightVH80 {
  height: 80vh;
}
.fullwidth {
  width: 100%;
}
.width80 {
  width: 80%;
}
.width60 {
  width: 60%;
}
.width50 {
  width: 50%;
}
.width40 {
  width: 40%;
}
.width30 {
  width: 30%;
}
.width20 {
  width: 20%;
}
.position__centerlize {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.flex__centerlize {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.hidden {
  display: none;
}
.word-wrap {
  word-wrap: break-word;
}
.flex {
  display: flex;
  /* margin: auto; */
}
.center-self {
  align-self: center;
}
.baseline-self {
  align-self: baseline;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
  white-space: pre-wrap;
}
.ver {
  flex-direction: column;
}
.hor {
  flex-direction: row;
  flex-flow: wrap;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.grow1 {
  flex-grow: 1;
}
.display_contents {
  display: contents;
}
.hoverable-op:hover {
  opacity: 0.8;
  cursor: pointer;
}
.hoverable-bg-lighter:hover {
  background: var(--light-color);
  cursor: pointer;
}
.hoverable-top:hover {
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
.hoverable-shadow-text:hover {
  text-shadow: 0px 0px 1px 1px var(--dark-color);
  cursor: pointer;
}
.hoverable-box-shadow:hover {
  box-shadow: 0px 0px 15px 0 var(--dark-color);
  cursor: pointer;
}
.box-shadow {
  box-shadow: 0px 0px 3px var(--dark-color);
}

/* sidebar */
.sticky_sidebar {
  position: sticky;
  top: 60px;
}
@media screen and (max-width: 600px) {
  .sidebar_order_1 {
    order: -1;
  }
}
/*Borders*/
.border {
  border: solid 1px var(--darker-color);
  border-radius: 3px;
}
.border-light {
  border: solid 1px var(--dark-color);
  border-radius: 3px;
}
.border-lighter {
  border: solid 1px #dcdcdc;
  border-radius: 3px;
}
.border-bottom {
  border-bottom: solid 1px var(--dark-color);
}
.border-top {
  border-top: solid 1px var(--dark-color);
}
.border-right {
  border-right: solid 1px var(--dark-color);
}
.border-left {
  border-left: solid 1px var(--dark-color);
}
.dashed {
  border-style: dashed;
}
.noborder {
  border: 0;
}
.noradius {
  border-radius: 0 !important;
}
.overflow_hidden {
  overflow: hidden;
}
.overflow_hidden_x {
  overflow-x: hidden;
}
.scroll_y {
  overflow-y: scroll;
}
.perpage .MuiSelect-select.MuiSelect-select {
  padding: 5px;
}
.perpage .MuiSelect-filled.MuiSelect-filled {
  padding: 2px 5px;
}
/*BGs*/
.bg-light {
  background: #eeeeee;
}
.bg-logo-sm {
  /* background-image: url("./assets/images/logo-w.png"); */
  width: 120px;
  height: 160px;
  background-size: contain;
  background-repeat: no-repeat;
}
/*Main*/
.bg-primary-light {
  background: var(--third-color);
}
.bg-primary {
  background-color: var(--primary-color);
}
.bg-secondary {
  background-color: var(--secondary-color);
}
.bg-primary-gradient {
  background: var(--primary-color);
  background: -moz-linear-gradient(
    300deg,
    var(--third-color) -80%,
    var(--primary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    var(--third-color) -80%,
    var(--primary-color) 100%
  );
  background: linear-gradient(
    300deg,
    var(--third-color) -80%,
    var(--primary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=var(--third-color),endColorstr=var(--primary-color),GradientType=1);
}
.bg-secondary-gradient {
  background: var(--secondary-color);
  background: -moz-linear-gradient(
    300deg,
    #fabd61 0%,
    var(--secondary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #fabd61 0%,
    var(--secondary-color) 100%
  );
  background: linear-gradient(300deg, #fabd61 0%, var(--secondary-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fabd61",endColorstr=var(--secondary-color),GradientType=1);
}
.bg-third-gradient {
  background: var(--third-color);
  background: -moz-linear-gradient(300deg, #e04856 0%, var(--third-color) 100%);
  background: -webkit-linear-gradient(
    300deg,
    #e04856 0%,
    var(--third-color) 100%
  );
  background: linear-gradient(300deg, #e04856 0%, var(--third-color) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e04856",endColorstr=var(--error-color),GradientType=1);
}
/*Second*/
.bg-second-primary-light {
  background: #d1deea;
}
.bg-second-primary {
  background-color: var(--second-primary-color);
}
.bg-second-secondary {
  background-color: var(--second-secondary-color);
}
.bg-second-primary-gradient {
  background: var(--second-primary-color);
  background: -moz-linear-gradient(
    300deg,
    #198eb4 0%,
    var(--second-primary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #198eb4 0%,
    var(--second-primary-color) 100%
  );
  background: linear-gradient(
    300deg,
    #198eb4 0%,
    var(--second-primary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#198eb4",endColorstr=var(--primary-color),GradientType=1);
}
.bg-second-secondary-gradient {
  background: var(--second-secondary-color);
  background: -moz-linear-gradient(
    300deg,
    #ffa37d 0%,
    var(--second-secondary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #ffa37d 0%,
    var(--second-secondary-color) 100%
  );
  background: linear-gradient(
    300deg,
    #ffa37d 0%,
    var(--second-secondary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffa37d",endColorstr=var(--secondary-color),GradientType=1);
}
.bg-second-third-gradient {
  background: var(--second-third-color);
  background: -moz-linear-gradient(
    300deg,
    #606061 0%,
    var(--second-third-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #606061 0%,
    var(--second-third-color) 100%
  );
  background: linear-gradient(
    300deg,
    #606061 0%,
    var(--second-third-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#606061",endColorstr=var(--error-color),GradientType=1);
}
/*third*/
.bg-third-primary-light {
  background: #ffebf7;
}
.bg-third-primary {
  background-color: var(--third-primary-color);
}
.bg-third-secondary {
  background-color: var(--third-secondary-color);
}
.bg-third-primary-gradient {
  background: var(--third-primary-color);
  background: -moz-linear-gradient(
    300deg,
    #673b55 0%,
    var(--third-primary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #673b55 0%,
    var(--third-primary-color) 100%
  );
  background: linear-gradient(
    300deg,
    #673b55 0%,
    var(--third-primary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#673b55",endColorstr=var(--primary-color),GradientType=1);
}
.bg-third-secondary-gradient {
  background: var(--third-secondary-color);
  background: -moz-linear-gradient(
    300deg,
    #b686a5 0%,
    var(--third-secondary-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #b686a5 0%,
    var(--third-secondary-color) 100%
  );
  background: linear-gradient(
    300deg,
    #b686a5 0%,
    var(--third-secondary-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b686a5",endColorstr=var(--secondary-color),GradientType=1);
}
.bg-third-third-gradient {
  background: var(--third-third-color);
  background: -moz-linear-gradient(
    300deg,
    #be6a58 0%,
    var(--third-third-color) 100%
  );
  background: -webkit-linear-gradient(
    300deg,
    #be6a58 0%,
    var(--third-third-color) 100%
  );
  background: linear-gradient(
    300deg,
    #be6a58 0%,
    var(--third-third-color) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#be6a58",endColorstr=var(--error-color),GradientType=1);
}
/**/
.bg-orange-gradient {
  background: #ff8e01;
  background: -moz-linear-gradient(300deg, #ffc981 0%, #ff8e01 100%);
  background: -webkit-linear-gradient(300deg, #ffc981 0%, #ff8e01 100%);
  background: linear-gradient(300deg, #ffc981 0%, #ff8e01 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffc981",endColorstr="#ff8e01",GradientType=1);
}

.p-square {
  padding: 1.5rem 0.5rem;
}

/*Print*/
.printOnly {
  display: none;
}
table {
  border-collapse: collapse;
}
table.invoice td {
  border: 0;
  border-left: solid 1px #eeeeee;
  padding: 5px;
}
table.invoice tr {
  border: solid 1px #eeeeee;
}
table.invoice .noborder {
  border: 0;
}
@page {
  size: A4;
  margin: 2mm 5mm 2mm 5mm;
}
.print-header td {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  padding-top: 0.5rem !important;
}
@media print {
  .printOnly {
    display: block;
  }
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
  table {
    page-break-inside: auto;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  table.invoice tbody {
    page-break-after: auto;
    display: table-row-group;
  }
  table.invoice thead {
    display: table-header-group;
  }
  table.invoice tfoot {
    display: table-footer-group;
  }
  .print-footer {
    display: table-footer-group;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    width: 100%;
  }
  table.invoice tr td div p span img {
    page-break-inside: avoid;
    page-break-after: auto;
    /* position: relative;
    overflow: hidden; */
  }
  .pagebreak {
    page-break-before: always !important;
    break-before: always !important;
  }
}
.voucher_unerline {
  text-decoration: underline;
}
.printf {
  /* direction: rtl !important; */
  padding-top: 2rem;
}
.printf-cell {
  padding: 5px 10px !important ;
  font-size: 0.75rem !important;
}

.indexing-input {
  padding: 10px;
  margin: 7px 0;
  border: solid 1px #c4c4c4;
  border-radius: 5px;
  max-height: 41px;
}
.dropbox:hover {
  z-index: 99;
}

/* Fix react carousel */
.carousel .slide {
  background: none !important;
}
.carousel-root {
  direction: ltr;
}
.carousel .thumbs-wrapper {
  margin: 0 !important;
}

.chips_wrap {
  white-space: normal !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
.rpv-toolbar,
.rpv-toolbar-center,
.rpv-toolbar-left {
  flex-flow: wrap;
}
.pdf_wrapper {
  position: relative;
  min-height: 330px;
}
.pdf_wrapper canvas {
  margin: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.ring {
  transform-origin: top;
  animation: ring 4s infinite ease-in-out 2s;
}
@keyframes ring {
  2% {
    -webkit-transform: rotate(18deg);
    -moz-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  4% {
    -webkit-transform: rotate(-16deg);
    -moz-transform: rotate(-16deg);
    transform: rotate(-16deg);
  }
  6% {
    -webkit-transform: rotate(14deg);
    -moz-transform: rotate(14deg);
    transform: rotate(14deg);
  }
  10% {
    -webkit-transform: rotate(-12deg);
    -moz-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  14% {
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  18% {
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  22% {
    -webkit-transform: rotate(6deg);
    -moz-transform: rotate(6deg);
    transform: rotate(6deg);
  }
  26% {
    -webkit-transform: rotate(-4deg);
    -moz-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  32% {
    -webkit-transform: rotate(2deg);
    -moz-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  38% {
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }
  44% {
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    transform: rotate(1deg);
  }
  50%,
  100% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    transform: rotate(0);
  }
}
